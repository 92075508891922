import { Alert, Box, Table, TableBody, TableHead, TableRow, type Theme } from "@mui/material"
import { styles } from "../styling/general"
import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { type IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../models/IWorkAssignment"
import TableRowSelect from "./TableRowSelect"
import { navigate } from "gatsby"
import { WORK_ASSIGNMENT_VIEW_URL, WORK_ASSIGNMENTS_URL } from "../../config/urls"
import WorkAssignmentProgress from "./WorkAssignmentProgress"
import { type IOrdering } from "../models/IOrdering"
import TableOrdering from "./TableOrdering"
import { ACCOUNT_ENDPOINT, type IAccount } from "../models/IAccount"
import FormatDate from "./format/FormatDate"
import { CUSTOMER_ENDPOINT, type ICustomer } from "../models/ICustomer"
import { useTableColumnShow } from "./TableColumnShow"
import { type IColumn } from "../models/component/IColumn"
import TableCellShow from "./TableCellShow"
import moment from "moment"
import ChangeDate from "../../pages/work_assignments/components/ChangeDate"
import WorkAssignmentLocationName from "./WorkAssignmentLocationName"
import ChangeCheckbox from "./ChangeCheckbox"
import { RestRepository } from "../repositories/RestRepository"
import TruncateText from "./TruncateText"
import ChangeHtmlField from "./ChangeHtmlField"
import WorkAssignmentConsultantName from "./WorkAssignmentConsultantName"
import { type SxProps } from "@mui/system"
import ChangeTextField from "./ChangeTextField"
import AssigneeEditor from "./AssigneeEditor"
import FormatNumber from "./format/FormatNumber"
import AssignAdminReviewer from "../../pages/work_assignments/components/AssignAdminReviewer"
import TableLoadingFull from "./TableLoadingFull"
import ReviewApproveHours from "./ReviewApproveHours"
import RliGettingPaidAmount from "./RliGettingPaidAmount"
import { type IReportFormat } from "../models/IReportFormat"
import ContactEditor from "./ContactEditor"
import { ACCESS_CAN_APPROVE_ADMIN_REVIEW, ACCESS_CAN_APPROVE_TECH_REVIEW } from "../../config/permissions"
import PrivateComponent from "./PrivateComponent"

const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)
const customerRepository = new RestRepository<ICustomer>(CUSTOMER_ENDPOINT)
const accountRepository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)

interface IProps {
  /**
   * Work assignment to display in the table.
   */
  workAssignments?: IWorkAssignment[]
  /**
   * Are the work assignments currently loading?
   */
  loading?: boolean
  limit?: number
  ordering?: IOrdering
  onOrdering?: (ordering: IOrdering) => void
  coloring?: boolean
  stickyHeader?: boolean
  rowStyle?: (wa: IWorkAssignment) => SxProps<Theme>
  onRefresh?: () => void
}

export const workAssignmentColumns = [
  {
    field: "identifier",
    title: "ID",
  },
  {
    field: "priority",
    title: "Priority",
  },
  {
    field: "customer__name",
    title: "Customer",
  },
  {
    field: "account__name",
    title: "Account",
  },
  {
    field: "location__name",
    title: "Location",
  },
  {
    field: "location__address",
    title: "Address",
  },
  {
    field: "location__city",
    title: "City",
  },
  {
    field: "location__state_region",
    title: "State/Region",
  },
  {
    field: "location__country",
    title: "Country",
  },
  {
    field: "location__postal_code",
    title: "Postal Code",
  },
  {
    field: "executive",
    title: "AE",
  },
  {
    field: "technical_admin",
    title: "TA",
  },
  {
    field: "inspection_category",
    title: "Inspection Category",
  },
  {
    field: "inspection_type",
    title: "Inspection Type",
  },
  {
    field: "report_format",
    title: "Report Format",
  },
  {
    field: "consultant",
    title: "Consultant",
  },
  {
    field: "assignee",
    title: "Assignee",
  },
  {
    field: "admin_reviewer_consultant",
    title: "Admin Reviewer",
  },
  {
    field: "admin_review_hours",
    title: "Admin Review Hours",
  },
  {
    field: "admin_review_hours_approved_by",
    title: "Admin Review Approve Hours",
  },
  {
    field: "tech_reviewer_consultant",
    title: "Tech Reviewer",
  },
  {
    field: "tech_review_due_date",
    title: "Tech Review Due Date",
  },
  {
    field: "tech_review_hours",
    title: "Tech Review Hours",
  },
  {
    field: "tech_review_hours_approved_by",
    title: "Tech Review Hours Approve",
  },
  {
    field: "tech_reviewer_2_consultant",
    title: "Tech Reviewer 2",
  },
  {
    field: "tech_review_2_due_date",
    title: "Tech Review 2 Due Date",
  },
  {
    field: "tech_review_2_hours",
    title: "Tech Review 2 Hours",
  },
  {
    field: "tech_review_2_hours_approved_by",
    title: "Tech Review 2 Hours Approve",
  },
  {
    field: "issued_date",
    title: "Added to WF",
  },
  {
    field: "visit_date",
    title: "Visit Date",
  },
  {
    field: "progress_consultant_reports_submitted",
    title: "Report Submitted by Consultant",
  },
  {
    field: "progress_report_sent_account",
    title: "Report Sent to Client",
  },
  {
    field: "consultant_due_date",
    title: "Consultant Due Date",
  },
  {
    field: "due_date",
    title: "Due Date",
  },
  {
    field: "progress",
    title: "Progress",
  },
  {
    field: "invoice__number",
    title: "Invoice",
  },
  {
    field: "customer__invoice_contact",
    title: "Customer Invoice Contact",
  },
  {
    field: "account__invoice_contact",
    title: "Account Invoice Contact",
  },
  {
    field: "rli_getting_paid",
    title: "RLI Getting Paid",
  },
  {
    field: "external_tracking",
    title: "External Tracking",
  },
  {
    field: "work_assignment_approved",
    title: "Approved",
  },
  {
    field: "notes",
    title: "Internal Notes",
  },
  {
    field: "notes_consultant",
    title: "Consultant Notes",
  },
  {
    field: "customer__notes",
    title: "Customer Notes",
  },
  {
    field: "account__notes",
    title: "Account Notes",
  },
  {
    field: "job_status",
    title: "Job Status",
  },
] as IColumn[]

/**
 * This is a reusable work assignment table that can be used anywhere.
 * @param {IProps} props see IProps for descriptions.
 * @returns {React.FC<IProps>} the work assignments in table format.
 */
const WorkAssignmentsTable: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    workAssignments,
    loading = false,
    limit = 5,
    onOrdering,
    ordering,
    coloring = false,
    stickyHeader = false,
    rowStyle = styles.waDaily,
    onRefresh,
  } = props

  const [was, setWas] = useState<IWorkAssignment[] | null>()

  const handleSelected = useCallback(async (workAssignment: IWorkAssignment) => {
    await navigate(`${WORK_ASSIGNMENT_VIEW_URL}/${workAssignment.id}`)
  }, [])

  const { selectedColumns } = useTableColumnShow({
    columns: workAssignmentColumns,
    pathname: WORK_ASSIGNMENTS_URL,
  })

  const handleWorkAssignmentChange = useCallback((wa: IWorkAssignment) => {
    setWas(was => was?.map(wa1 => (wa.id === wa1.id ? wa : wa1)))
  }, [])

  const handleWaRefresh = useCallback(() => {
    onRefresh?.()
  }, [])

  useEffect(() => {
    setWas(workAssignments)
  }, [workAssignments])

  return (
    <>
      <Table stickyHeader={stickyHeader} size="small">
        <TableHead>
          <TableRow>
            <TableCellShow columns={selectedColumns} field="identifier">
              <TableOrdering ordering={ordering} field="id" title="ID" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="priority">
              <TableOrdering ordering={ordering} field="priority" title="Priority" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="customer__name">
              <TableOrdering ordering={ordering} field="customer__name" title="Customer" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="account__name">
              <TableOrdering ordering={ordering} field="account__name" title="Account" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="location__name">
              <TableOrdering ordering={ordering} field="location__address" title="Location" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="location__address">
              <TableOrdering ordering={ordering} field="location__address" title="Address" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="location__city">
              <TableOrdering ordering={ordering} field="location__city" title="City" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="location__state_region">
              <TableOrdering ordering={ordering} field="location__state_region" title="State/Region" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="location__country">
              <TableOrdering ordering={ordering} field="location__country" title="Country" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="location__postal_code">
              <TableOrdering ordering={ordering} field="location__postal_code" title="Postal Code" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="inspection_category">
              <TableOrdering
                ordering={ordering}
                field="inspection_category"
                title={
                  <>
                    Inspection
                    <br />
                    Category
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="inspection_type">
              <TableOrdering ordering={ordering} field="inspection_type" title="Inspection Type" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="executive">
              AE
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="technical_admin">
              TA
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="report_format">
              <TableOrdering ordering={ordering} field="report_format" title="Report Format" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="consultant">
              Consultant
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="assignee">
              <TableOrdering ordering={ordering} field="assignee" title="Assignee" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="tech_reviewer_consultant">
              <TableOrdering ordering={ordering} field="tech_reviewer_consultant" title="Tech Reviewer" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="tech_review_due_date" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering
                ordering={ordering}
                field="tech_review_due_date"
                title={
                  <>
                    Tech Review
                    <br />
                    Due Date
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="tech_review_hours" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering
                ordering={ordering}
                field="tech_review_hours"
                title={
                  <>
                    Tech Review
                    <br />
                    Hours
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="tech_review_hours_approved_by" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering
                ordering={ordering}
                field="tech_review_hours_approved_by"
                title={
                  <>
                    Tech Review
                    <br />
                    Approve Hours
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="tech_reviewer_2_consultant" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering ordering={ordering} field="tech_reviewer_2_consultant" title="Tech Reviewer 2" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="tech_review_2_due_date" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering
                ordering={ordering}
                field="tech_review_2_due_date"
                title={
                  <>
                    Tech Review 2
                    <br />
                    Due Date
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="tech_review_2_hours" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering
                ordering={ordering}
                field="tech_review_2_hours"
                title={
                  <>
                    Tech Review 2
                    <br />
                    Hours
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="tech_review_2_hours_approved_by" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering
                ordering={ordering}
                field="tech_review_2_hours_approved_by"
                title={
                  <>
                    Tech Review 2
                    <br />
                    Approve Hours
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="admin_reviewer_consultant">
              <TableOrdering ordering={ordering} field="admin_reviewer_consultant" title="Admin Reviewer" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="admin_review_hours" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering
                ordering={ordering}
                field="admin_review_hours"
                title={
                  <>
                    Admin Review
                    <br />
                    Hours
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="admin_review_hours_approved_by" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering
                ordering={ordering}
                field="admin_review_hours_approved_by"
                title={
                  <>
                    Admin Review
                    <br />
                    Approve Hours
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="issued_date">
              <TableOrdering ordering={ordering} field="issued_date" title="Added to WF" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="visit_date">
              <TableOrdering ordering={ordering} field="visit_date" title="Visit Date" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="consultant_due_date">
              <TableOrdering
                ordering={ordering}
                field="consultant_due_date"
                title={
                  <>
                    Consultant
                    <br />
                    Due Date
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="due_date" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering ordering={ordering} field="due_date" title="Due Date" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="progress_consultant_reports_submitted" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering
                ordering={ordering}
                field="progress_consultant_reports_submitted"
                title={
                  <>
                    Report Submitted
                    <br />
                    By Consultant
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="progress_report_sent_account" sx={{ whiteSpace: "nowrap" }}>
              <TableOrdering
                ordering={ordering}
                field="progress_report_sent_account"
                title={
                  <>
                    Report Sent
                    <br />
                    to Client
                  </>
                }
                onOrdering={onOrdering}
              />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="progress">
              <TableOrdering ordering={ordering} field="progress" title="Progress (Next Step)" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="invoice__number">
              <TableOrdering ordering={ordering} field="invoice__number" title="Invoice" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="customer__invoice_contact">
              Customer
              <br />
              Invoice Contact
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="account__invoice_contact">
              Account
              <br />
              Invoice Contact
            </TableCellShow>
            <TableCellShow
              columns={selectedColumns}
              field="rli_getting_paid"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              RLI Getting Paid
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="external_tracking">
              <TableOrdering ordering={ordering} field="external_tracking" title="External Tracking" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="work_assignment_approved">
              <TableOrdering ordering={ordering} field="work_assignment_approved" title="Approved" onOrdering={onOrdering} />
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="notes">
              Internal Notes
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="notes_consultant">
              Consultant Notes
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="customer__notes">
              Customer Notes
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="account__notes">
              Account Notes
            </TableCellShow>
            <TableCellShow columns={selectedColumns} field="job_status">
              Job Status
            </TableCellShow>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            was?.map(wa => {
              const rowColoring: SxProps<Theme> = coloring ? rowStyle(wa) : {}
              let daysLate = wa.is_report_sent_account_late && wa.due_date !== null ? moment().diff(moment(wa.due_date), "days") : 0

              let daysSince = 0

              if (wa.visit_date !== null) {
                daysSince = moment().diff(moment(wa.visit_date), "days")
              }
              if (wa.progress_report_sent_account !== null && wa.visit_date !== null) {
                daysSince = moment(wa.progress_report_sent_account).diff(moment(wa.visit_date), "days")
              }

              if (wa.is_report_sent_account_late && wa.due_date !== null && wa.progress_report_sent_account !== null) {
                daysLate = moment(wa.progress_report_sent_account).diff(moment(wa.due_date), "days")
              }
              const consultantDaysLate =
                wa.is_consultant_report_late && wa.consultant_due_date !== null ? moment().diff(moment(wa.consultant_due_date), "days") : 0

              const consultantReportIsLate: any = { ...rowColoring, ...styles.consultantReportIsLate(wa) }
              const waIsLate: any = { ...rowColoring, ...styles.waIsLate(wa) }

              const waSinceLate: any = { ...rowColoring, ...styles.waSinceLate(wa) }
              return (
                <TableRowSelect key={wa.id} item={wa} onSelected={handleSelected}>
                  <TableCellShow
                    columns={selectedColumns}
                    field="identifier"
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {wa.identifier}
                  </TableCellShow>
                  <TableCellShow
                    columns={selectedColumns}
                    field="priority"
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {wa.priority_name}
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="customer__name"
                  >
                    <TruncateText text={(wa.customer as ICustomer)?.name} />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="account__name"
                  >
                    <TruncateText text={(wa.account as IAccount)?.name} />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="location__name"
                  >
                    <WorkAssignmentLocationName wa={wa} />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="location__address"
                  >
                    <TruncateText text={wa.location?.address} />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="location__city"
                  >
                    <TruncateText text={wa.location?.city} />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="location__state_region"
                  >
                    {wa.location?.state_region}
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="location__country"
                  >
                    {wa.location?.country}
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="location__postal_code"
                  >
                    {wa.location?.postal_code}
                  </TableCellShow>
                  <TableCellShow sx={rowColoring} columns={selectedColumns} field="inspection_category">
                    {wa.inspection_category}
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="inspection_type"
                  >
                    <TruncateText text={wa.inspection_type} altText={wa.inspection_type_name} placement="left" />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="executive"
                  >
                    {wa.executive?.name}
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="technical_admin"
                  >
                    {wa.technical_admin?.name}
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="report_format"
                  >
                    <TruncateText
                      text={(wa.report_format as IReportFormat)?.name}
                      altText={(wa.report_format as IReportFormat)?.name}
                      placement="left"
                    />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="consultant"
                  >
                    <WorkAssignmentConsultantName wa={wa} />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="assignee"
                  >
                    <AssigneeEditor wa={wa} asButton onChange={handleWorkAssignmentChange} />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="tech_reviewer_consultant"
                  >
                    {!wa.tech_review_needed ? <>Not Needed</> : wa.tech_reviewer_consultant?.name}
                  </TableCellShow>
                  <TableCellShow sx={rowColoring} columns={selectedColumns} field="tech_review_due_date">
                    {!wa.tech_review_needed ? (
                      <>Not Needed</>
                    ) : (
                      <>
                        {wa.tech_reviewer_consultant !== null && (
                          <ChangeDate
                            waId={wa.id}
                            field="tech_review_due_date"
                            onChange={handleWorkAssignmentChange}
                            title="Tech Review Due Date"
                            value={wa.tech_review_due_date}
                          />
                        )}
                      </>
                    )}
                  </TableCellShow>
                  <TableCellShow sx={rowColoring} columns={selectedColumns} field="tech_review_hours">
                    <FormatNumber value={wa.tech_review_hours} prefixUnits={false} twoDecimalPlaces />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="tech_review_hours_approved_by"
                  >
                    <PrivateComponent
                      groupNames={[ACCESS_CAN_APPROVE_TECH_REVIEW]}
                      component={
                        <ReviewApproveHours
                          workAssignment={wa}
                          hoursApprovedBy={wa.tech_review_hours_approved_by}
                          action="tech_reviewer_approve_hours_toggle"
                          paging={{
                            filters: [
                              {
                                field: "number",
                                value: 1,
                              },
                            ],
                          }}
                          onChange={handleWorkAssignmentChange}
                        />
                      }
                      componentElse={<>No Access</>}
                    />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="tech_reviewer_2_consultant"
                  >
                    {!wa.tech_review_2_needed ? <>Not Needed</> : wa.tech_reviewer_2_consultant?.name}
                  </TableCellShow>
                  <TableCellShow sx={rowColoring} columns={selectedColumns} field="tech_review_2_due_date">
                    {!wa.tech_review_2_needed ? (
                      <>Not Needed</>
                    ) : (
                      <>
                        {wa.tech_reviewer_consultant !== null && (
                          <ChangeDate
                            waId={wa.id}
                            field="tech_review_2_due_date"
                            onChange={handleWorkAssignmentChange}
                            title="Tech Review 2 Due Date"
                            value={wa.tech_review_2_due_date}
                          />
                        )}
                      </>
                    )}
                  </TableCellShow>
                  <TableCellShow sx={rowColoring} columns={selectedColumns} field="tech_review_2_hours">
                    <FormatNumber value={wa.tech_review_2_hours} prefixUnits={false} twoDecimalPlaces />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="tech_review_2_hours_approved_by"
                  >
                    <PrivateComponent
                      groupNames={[ACCESS_CAN_APPROVE_TECH_REVIEW]}
                      component={
                        <ReviewApproveHours
                          workAssignment={wa}
                          hoursApprovedBy={wa.tech_review_2_hours_approved_by}
                          action="tech_reviewer_approve_hours_toggle"
                          paging={{
                            filters: [
                              {
                                field: "number",
                                value: 2,
                              },
                            ],
                          }}
                          onChange={handleWorkAssignmentChange}
                        />
                      }
                      componentElse={<>No Access</>}
                    />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="admin_reviewer_consultant"
                  >
                    <AssignAdminReviewer wa={wa} small onChangeWithReturn={handleWorkAssignmentChange} />
                  </TableCellShow>
                  <TableCellShow sx={rowColoring} columns={selectedColumns} field="admin_review_hours">
                    <FormatNumber value={wa.admin_review_hours} prefixUnits={false} twoDecimalPlaces />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="admin_review_hours_approved_by"
                  >
                    <PrivateComponent
                      groupNames={[ACCESS_CAN_APPROVE_ADMIN_REVIEW]}
                      component={
                        <ReviewApproveHours
                          workAssignment={wa}
                          hoursApprovedBy={wa.admin_review_hours_approved_by}
                          action="admin_reviewer_approve_hours_toggle"
                          onChange={handleWorkAssignmentChange}
                        />
                      }
                      componentElse={<>No Access</>}
                    />
                  </TableCellShow>
                  <TableCellShow sx={rowColoring} columns={selectedColumns} field="issued_date">
                    <FormatDate value={wa.issued_date as string} />
                  </TableCellShow>
                  <TableCellShow sx={waSinceLate} columns={selectedColumns} field="visit_date">
                    <ChangeDate
                      waId={wa.id}
                      field="visit_date"
                      onChange={handleWorkAssignmentChange}
                      title="Visit Date"
                      value={wa.visit_date}
                    />
                    {wa.progress_report_sent_account === null && daysSince > 0 && (
                      <Box
                        component="small"
                        sx={{
                          ml: 1,
                          display: "block",
                        }}
                      >
                        {daysSince} day{daysSince > 1 && "s"} since visit
                      </Box>
                    )}
                  </TableCellShow>
                  <TableCellShow sx={consultantReportIsLate} columns={selectedColumns} field="consultant_due_date">
                    <ChangeDate
                      waId={wa.id}
                      field="consultant_due_date"
                      onChange={handleWorkAssignmentChange}
                      title="Consultant Due Date"
                      value={wa.consultant_due_date}
                      dateComment={wa.consultant_due_date_comment}
                    />
                    {wa.is_consultant_report_late && wa.consultant_due_date !== null && (
                      <Box
                        component="small"
                        sx={{
                          ml: 1,
                          display: "block",
                        }}
                      >
                        {consultantDaysLate} day{consultantDaysLate > 1 && "s"} late
                      </Box>
                    )}
                  </TableCellShow>
                  <TableCellShow sx={waIsLate} columns={selectedColumns} field="due_date">
                    <ChangeDate
                      waId={wa.id}
                      field="due_date"
                      onChange={handleWorkAssignmentChange}
                      title="Due Date"
                      value={wa.due_date}
                      dateComment={wa.due_date_comment}
                    />
                    {wa.is_report_sent_account_late && wa.due_date !== null && (
                      <Box
                        component="small"
                        sx={{
                          ml: 1,
                          display: "block",
                        }}
                      >
                        {daysLate} day{daysLate > 1 && "s"} late
                      </Box>
                    )}
                  </TableCellShow>
                  <TableCellShow sx={rowColoring} columns={selectedColumns} field="progress_consultant_reports_submitted">
                    <FormatDate value={wa.progress_consultant_reports_submitted} />
                  </TableCellShow>
                  <TableCellShow sx={rowColoring} columns={selectedColumns} field="progress_report_sent_account">
                    <FormatDate value={wa.progress_report_sent_account} />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="progress"
                  >
                    <WorkAssignmentProgress workAssignment={wa} onChange={handleWorkAssignmentChange} />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="invoice__number"
                  >
                    {/* todo: this should be fixed on the server side. */}
                    {typeof wa.invoice === "string" ? wa.invoice : wa.invoice?.number}
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="customer__invoice_contact"
                  >
                    <ContactEditor
                      modelId={(wa.customer as ICustomer).id}
                      field="invoice_contact"
                      title="Customer Invoice Contact"
                      value={(wa.customer as ICustomer).invoice_contact}
                      repository={customerRepository}
                      onChange={handleWaRefresh}
                    />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="account__invoice_contact"
                  >
                    <ContactEditor
                      modelId={(wa.account as IAccount).id}
                      field="invoice_contact"
                      title="Account Invoice Contact"
                      value={(wa.account as IAccount).invoice_contact}
                      repository={accountRepository}
                      onChange={handleWaRefresh}
                    />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      textAlign: "right",
                    }}
                    columns={selectedColumns}
                    field="rli_getting_paid"
                  >
                    <RliGettingPaidAmount workAssignment={wa} />
                  </TableCellShow>
                  <TableCellShow
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                    columns={selectedColumns}
                    field="external_tracking"
                  >
                    <ChangeTextField
                      modelId={wa.id}
                      item={wa}
                      field="external_tracking"
                      title="External Tracking"
                      onChange={handleWorkAssignmentChange}
                      value={wa.external_tracking}
                      repository={waRepository}
                    />
                  </TableCellShow>
                  <TableCellShow sx={rowColoring} columns={selectedColumns} field="work_assignment_approved">
                    <ChangeCheckbox
                      modelId={wa.id}
                      item={wa}
                      field="work_assignment_approved"
                      title="Approved"
                      label="Has the work assignment be approved?"
                      onChange={handleWorkAssignmentChange}
                      value={wa.work_assignment_approved}
                      repository={waRepository}
                    />
                  </TableCellShow>
                  <TableCellShow
                    columns={selectedColumns}
                    field="notes"
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <ChangeHtmlField
                      modelId={wa.id}
                      item={wa}
                      field="notes"
                      title="Internal Notes"
                      onChange={handleWorkAssignmentChange}
                      value={wa.notes}
                      repository={waRepository}
                    />
                  </TableCellShow>
                  <TableCellShow
                    columns={selectedColumns}
                    field="notes_consultant"
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <ChangeHtmlField
                      modelId={wa.id}
                      item={wa}
                      field="notes_consultant"
                      title="Consultant Notes"
                      onChange={handleWorkAssignmentChange}
                      value={wa.notes_consultant}
                      repository={waRepository}
                    />
                  </TableCellShow>
                  <TableCellShow
                    columns={selectedColumns}
                    field="customer__notes"
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <ChangeHtmlField
                      modelId={(wa.customer as ICustomer).id}
                      item={wa.customer}
                      field="notes"
                      title="Customer Notes"
                      onChange={handleWaRefresh}
                      value={(wa.customer as ICustomer).notes}
                      repository={customerRepository}
                    />
                  </TableCellShow>
                  <TableCellShow
                    columns={selectedColumns}
                    field="account__notes"
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <ChangeHtmlField
                      modelId={(wa.account as IAccount).id}
                      item={wa.account}
                      field="notes"
                      title="Account Notes"
                      onChange={handleWaRefresh}
                      value={(wa.account as IAccount).notes}
                      repository={accountRepository}
                    />
                  </TableCellShow>
                  <TableCellShow
                    columns={selectedColumns}
                    field="job_status"
                    sx={{
                      ...rowColoring,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <ChangeHtmlField
                      modelId={wa.id}
                      item={wa}
                      field="job_status"
                      title="Job Status"
                      onChange={handleWorkAssignmentChange}
                      value={wa.job_status}
                      repository={waRepository}
                    />
                  </TableCellShow>
                </TableRowSelect>
              )
            })}
        </TableBody>
      </Table>
      {!loading && (was === null || was?.length === 0) && (
        <Alert color="info" sx={{ m: 1 }}>
          No work assignments found.
        </Alert>
      )}
      <TableLoadingFull loading={loading} rows={limit} />
    </>
  )
}

export default WorkAssignmentsTable
