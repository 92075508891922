import * as React from "react"
import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { Alert, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { type IWorkAssignment } from "../../../shared/models/IWorkAssignment"
import TablePaging from "../../../shared/components/TablePaging"
import { type IPaging } from "../../../shared/models/IPaging"
import { CONNECTION_ERROR, type IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { type IFilter } from "../../../shared/models/IFilter"
import { type IConsultant } from "../../../shared/models/main/IConsultant"
import { type IPagedResults } from "../../../shared/models/IPagedResults"
import { CONSULTANTS_WORK_ASSIGNMENT_ENDPOINT, type IWorkAssignmentConsultant } from "../../../shared/models/IWorkAssignmentConsultant"
import TableOrdering from "../../../shared/components/TableOrdering"
import TruncateText from "../../../shared/components/TruncateText"
import { type ICustomer } from "../../../shared/models/ICustomer"
import { type IAccount } from "../../../shared/models/IAccount"
import WorkAssignmentLocationName from "../../../shared/components/WorkAssignmentLocationName"
import ConsultantRate from "../../../shared/components/ConsultantRate"
import FormatDate from "../../../shared/components/format/FormatDate"
import TableLoadingFull from "../../../shared/components/TableLoadingFull"
import TableRowSelect from "../../../shared/components/TableRowSelect"
import { navigate } from "gatsby"
import { WORK_ASSIGNMENT_VIEW_URL } from "../../../config/urls"
import { type IReportFormat } from "../../../shared/models/IReportFormat"

const wacRepository = new RestRepository<IWorkAssignmentConsultant>(CONSULTANTS_WORK_ASSIGNMENT_ENDPOINT)

const limit = 10

interface IProps {
  consultant: IConsultant
  page: number
  setPage: (page: number) => void
}

/**
 * Displays the currently active work assignments for a consultant.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} a panel for the active work assignments for a consultant.
 */
const WorkHistory: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { consultant, page, setPage } = props
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<IConnectionError | null>(null)
  const [workAssignments, setWorkAssignments] = useState<IPagedResults<IWorkAssignmentConsultant> | null>(null)

  const filters = useMemo(() => {
    if (consultant?.id === undefined) {
      return []
    }
    return [
      {
        field: "consultant",
        value: consultant.id,
      },
      {
        field: "accepted",
        value: true,
      },
      {
        field: "report_sent_to_account",
        value: true,
      },
    ] as IFilter[]
  }, [consultant?.id])

  const handlePaging = useCallback((_e: ChangeEvent<unknown> | null, page1: number) => {
    setPage(page1)
    setWorkAssignments(null)
  }, [])

  const loadWorkAssignments = useCallback(async () => {
    try {
      setErrorMessage(null)
      setLoading(true)
      const paging: IPaging = {
        filters,
        limit,
        offset: limit * (page - 1),
        ordering: {
          field: "work_assignment__progress_report_sent_account",  // Change the field to 'amount'
          direction: "desc" // Change the direction to 'desc'
        }
      }
      const results = await wacRepository.findAll(paging)
      setWorkAssignments(results)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setErrorMessage(reason.response as IConnectionError)
      } else {
        setErrorMessage(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [filters, page])


  const handleSelected = useCallback(async (workAssignment: IWorkAssignment) => {
    await navigate(`${WORK_ASSIGNMENT_VIEW_URL}/${workAssignment.id}`)
  }, [])

  useEffect(() => {
    if (workAssignments === null) {
      void (async () => {
        await loadWorkAssignments()
      })()
    }
  }, [workAssignments, loadWorkAssignments])

  return (
    <>
      <ErrorMessage error={errorMessage} />
      {(workAssignments !== null && workAssignments.count > 0) || loading ? (
        <>
          <Grid container sx={{ mt: 1 }} spacing={1} alignItems="center">
            <Grid item xs>
              <Typography variant="h6">Completed Work Assignments: {workAssignments?.count} </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mb: 2 }}>
            <TableContainer>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Account</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell sx={{ textAlign: "right" }}>Amount</TableCell>
                    <TableCell>Inspection Category</TableCell>
                    <TableCell>
                      <TableOrdering field="visit_date" title="Visit Date" />
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      <TableOrdering field="progress_consultant_reports_submitted" title={<>Report Submitted <br/> By Consultant</>} />
                    </TableCell>
                    <TableCell>
                      <TableOrdering field="consultant_due_date" title="Consultant Due Date" />
                    </TableCell>
                    <TableCell>
                      <TableOrdering field="progress_report_sent_account" title="Report Sent to Client" />
                    </TableCell>
                    <TableCell>Report Format</TableCell>
                    <TableCell>Average Score</TableCell>
                    <TableCell>Technical Quality</TableCell>
                    <TableCell>Formatting Quality</TableCell>
                    <TableCell>Responsiveness</TableCell>
                    <TableCell>Tech Reviewer</TableCell>
                    <TableCell>Tech Review Comment</TableCell>
                    <TableCell>Tech 2 Reviewer</TableCell>
                    <TableCell>Tech 2 Review Comment</TableCell>
                    <TableCell>Admin Reviewer</TableCell>
                    <TableCell>Admin Review Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading &&
                    workAssignments?.results?.map(wac => {
                      const workAssignment = wac.work_assignment as IWorkAssignment
                      return (
                        <TableRowSelect key={wac.id} item={wac.work_assignment} onSelected={handleSelected}>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {workAssignment.identifier}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={(workAssignment.customer as ICustomer)?.name} />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={(workAssignment.account as IAccount)?.name} />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <WorkAssignmentLocationName wa={workAssignment} />
                          </TableCell>
                          <TableCell sx={{ textAlign: "right" }}>
                            <ConsultantRate wac={wac} showDetails />
                          </TableCell>
                          <TableCell>
                            {workAssignment.inspection_category}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center"}}>
                            <FormatDate value={workAssignment.visit_date} />
                          </TableCell>
                          <TableCell>
                            <FormatDate value={workAssignment.progress_consultant_reports_submitted} />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <FormatDate value={workAssignment.consultant_due_date} />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <FormatDate value={workAssignment.progress_report_sent_account} />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={(workAssignment.report_format as IReportFormat)?.name} placement="bottom"/>
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                              {wac.scoring_average}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                              {wac.scoring_technical_quality}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                              {wac.scoring_formatting_quality}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                              {wac.scoring_responsiveness}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={workAssignment.tech_reviewer_consultant?.name ?? 'Not Needed'} />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={wac.scoring_tech_review_comment} placement="bottom" />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={workAssignment.tech_reviewer_2_consultant?.name ?? 'Not Needed'} />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={wac.scoring_tech_2_review_comment} placement="bottom"/>
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={workAssignment.admin_reviewer_consultant?.name ?? 'Not Needed'} />
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            <TruncateText text={wac.scoring_admin_review_comment} placement="bottom"/>
                          </TableCell>
                        </TableRowSelect>
                      )
                    })}
                </TableBody>
              </Table>
              <TableLoadingFull loading={loading} rows={limit} />
            </TableContainer>
          </Box>
          {workAssignments !== null && (
            <TablePaging
              count={Math.ceil(workAssignments.count / limit)}
              total={workAssignments.count}
              page={page}
              onPaging={handlePaging}
            />
          )}
        </>
      ) : (
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs>
            <Alert severity="info">No work assignments found.</Alert>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default WorkHistory
