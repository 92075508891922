import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Avatar, Box, Button, Chip, Grid, Link, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material"
import StarIcon from "@mui/icons-material/Star"
import { Link as RouterLink, useParams } from "@reach/router"
import Moment from "react-moment"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { useApiRead } from "../../shared/hooks/useApiRead"
import {
  consultantLocation,
  CONSULTANTS_ENDPOINT,
  getConsultantPhone,
  getConsultantPhone2,
  type IConsultant,
} from "../../shared/models/main/IConsultant"
import ViewLoading from "../../shared/components/ViewLoading"
import { CONSULTANTS_EDIT_URL } from "../../config/urls"
import ViewProperty from "../../shared/components/ViewProperty"
import CheckMark from "../../shared/components/CheckMark"
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../config/config"
import GoogleMapsGeoLink from "../../shared/components/GoogleMapsGeoLink"
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel"
import { styles } from "../../shared/styling/general"
import { RestRepository } from "../../shared/repositories/RestRepository"
import ConsultantLocationMap from "../../shared/components/ConsultantLocationMap"
import { CONNECTION_ERROR, type IConnectionError } from "../../shared/models/IConnectionError"
import ConsultantSpecialties from "../../shared/components/ConsultantSpecialties"
import SpecialtiesEditor from "./components/SpecialtiesEditor"
import ConsultantFileEditor from "./components/ConsultantFileEditor"
import ErrorMessage from "../../shared/components/ErrorMessage"
import FormatDate from "../../shared/components/format/FormatDate"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { type DashboardStore } from "../../store"
import type IConsultantsViewState from "../../store/settings/models/IConsultantsViewState"
import { operations } from "../../store/settings"
import ConsultantRating from "../../shared/components/consultants/ConsultantRating"
import { LoadingButton } from "@mui/lab"
import EditIcon from "@mui/icons-material/Edit"
import { ACCESS_EXPENSE_REPORT_GROUP } from "../../config/permissions"
import PrivateComponent from "../../shared/components/PrivateComponent"
import useAuth from "../../shared/hooks/useAuth"
import ConsultantWorkAssignmentsPanel from "./components/ConsultantWorkAssignmentsPanel"
import ExpenseReportsEditor from "./components/ExpenseReportsEditor"
import AcceptDeclineHistory from "./components/AcceptDeclineHistory"
import WorkHistory from "./components/WorkHistory"
import HelpDocsDrawer from "../../shared/components/help/HelpDocsDrawer"

const consultantRepository = new RestRepository<IConsultant>(CONSULTANTS_ENDPOINT)

/**
 * Renders the consultant in a view.
 *
 * @returns {React.FC} the component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data: consultant, call: loadConsultant, loading, error } = useApiRead<IConsultant>({ apiFunction: consultantRepository.read })

  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const { id } = useParams()
  const viewState: IConsultantsViewState = useSelector((state: DashboardStore) => state.settings.consultantsView, shallowEqual)

  const { tab, handleTabChange } = useTabPanel(viewState?.id === id ? { initialTab: viewState?.tab } : undefined)

  const [page, setPage] = useState(viewState?.id === id ? viewState?.page : 1)

  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const { currentUser, isUserInGroups } = useAuth()

  const dispatch = useDispatch()

  // todo: move to local storage
  useEffect(() => {
    const viewState: IConsultantsViewState = {
      id,
      page,
      tab,
    }
    dispatch(operations.setConsultantsView(viewState))
  }, [id, page, tab])

  const handleConsultantChange = useCallback(() => {
    loadConsultant()
  }, [])

  const handleEnableLogin = useCallback(async () => {
    if (consultant?.id !== undefined) {
      setSaving(true)
      setSavingError(null)
      try {
        await consultantRepository.action(consultant.id, "enable_user_login")
        loadConsultant()
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response as IConnectionError)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
    }
  }, [consultant])

  const handleDisableLogin = useCallback(async () => {
    if (consultant?.id !== undefined) {
      setSaving(true)
      setSavingError(null)
      try {
        await consultantRepository.action(consultant.id, "disable_user_login")
        loadConsultant()
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response as IConnectionError)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
    }
  }, [consultant])

  const additionalLocations = useMemo(() => {
    if (consultant !== undefined && consultant.longitude_alt !== 0) {
      const consultantLocation1 = consultantLocation(consultant, true)
      if (consultantLocation1 !== null) {
        return [consultantLocation1]
      }
    }
    return []
  }, [])

  const handleRefresh = useCallback(() => {
    loadConsultant()
  }, [])

  const handleSendReminderEmail = useCallback(async () => {
    if (consultant?.id !== undefined) {
      setSaving(true)
      setSavingError(null)
      try {
        await consultantRepository.action(consultant.id, "send_weekly_reminder_email")
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setSavingError(reason.response as IConnectionError)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
      setSaving(false)
    }
  }, [consultant])

  return (
    <WorkflowPage footer={false}>
      <Box sx={styles.pageFunc(isSmall)}>
        <ViewLoading loading={loading} />
        <ErrorMessage error={error} />
        <ErrorMessage error={savingError} />
        {consultant !== undefined && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Avatar
                    alt={`${consultant.first_name} ${consultant.last_name}`}
                    sx={{
                      width: 64,
                      height: 64,
                    }}
                    src={consultant.profile_image.exists !== false ? consultant.profile_image.read_url : ""}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h3" component="h2" onClick={handleRefresh} sx={{ cursor: "pointer" }}>
                    {consultant.first_name} {consultant.last_name}
                    <Box component="small" sx={styles.consultant.identifier}>
                      {consultant.identifier}
                    </Box>
                  </Typography>
                  <Typography variant="subtitle1">{consultant.legal_entity_name}</Typography>
                  <ConsultantRating rating={consultant.rating} />
                </Grid>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      {consultant.active ? <Chip label="Active" color="success" /> : <Chip label="Inactive" color="error" />}
                    </Grid>
                    <Grid item>{consultant.highly_protected_risk && <Chip label="HPR" color="info" />}</Grid>
                    <Grid item>{consultant.property_lite && <Chip label="PL" color="info" />}</Grid>
                    <Grid item>{consultant.highly_protected_risk_lite && <Chip label="HPR Lite" color="info" />}</Grid>
                    <Grid item>{consultant.boiler_and_machinery && <Chip label="B & M" color="info" />}</Grid>
                    <Grid item>{consultant.casualty && <Chip label="Casualty" color="info" />}</Grid>
                    <Grid item>{consultant.tech_reviewer && <Chip label="Tech Reviewer" color="info" />}</Grid>
                    <Grid item>{consultant.admin_reviewer && <Chip label="Admin Reviewer" color="info" />}</Grid>
                    <Grid item>{consultant.plan_reviewer && <Chip label="Plan Reviewer" color="info" />}</Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <ViewLoading loading={loading || saving} message="Updating..." />
                </Grid>
                <Grid item>
                  <Button component={RouterLink} to={`${CONSULTANTS_EDIT_URL}/${consultant.id}`} startIcon={<EditIcon />}>
                    Consultant
                  </Button>
                </Grid>
                <Grid item>
                  <HelpDocsDrawer showButton showButtonLabel={false} pageId={235} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.tabsBox}>
                <Tabs value={tab} onChange={handleTabChange}>
                  <Tab label="Primary" />
                  <Tab label="Additional Info" />
                  <Tab label="Work Assignments" />
                  <Tab label="Work History" />
                  <Tab label="Accept/Decline History" />
                  <Tab label="Specialties" />
                  <Tab
                    label={<PrivateComponent groupNames={[ACCESS_EXPENSE_REPORT_GROUP]} component={<>Expense Reports</>} />}
                    disabled={isUserInGroups?.([ACCESS_EXPENSE_REPORT_GROUP], currentUser) === false}
                  />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <ViewProperty label="User Login">
                      {consultant.profile !== undefined && consultant.profile !== null ? (
                        <>
                          {consultant.profile.user.is_active ? (
                            <Grid container alignContent="center">
                              <Grid item xs>
                                {consultant.profile.user.username} is
                                <Chip size="small" color="success" label="Enabled" sx={{ ml: 1 }} />
                              </Grid>
                              <Grid item>
                                <Button color="error" size="small" onClick={handleDisableLogin} disabled={saving}>
                                  Disable
                                </Button>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container>
                              <Grid item xs>
                                {consultant.profile.user.username} is
                                <Chip size="small" color="warning" label="Disabled" sx={{ ml: 1 }} />
                              </Grid>
                              <Grid item>
                                <Button color="success" size="small" onClick={handleEnableLogin} disabled={saving}>
                                  Enable
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </>
                      ) : (
                        <>
                          <Button size="small" onClick={handleEnableLogin} disabled={saving}>
                            Enable Login
                          </Button>
                        </>
                      )}
                    </ViewProperty>

                    {consultant.profile !== undefined && consultant.profile !== null && (
                      <ViewProperty label="Last Login">
                        <FormatDate value={consultant.profile.user.last_login} />
                      </ViewProperty>
                    )}

                    <ViewProperty label={<>Email {consultant.preferred_com === "email" && <StarIcon fontSize="small" />}</>}>
                      <Link href={`mailto:${consultant.first_name} ${consultant.last_name}<${consultant.email}>`}>{consultant.email}</Link>
                    </ViewProperty>

                    <ViewProperty label={<>Email 2</>} show={consultant.email_2 !== "" && consultant.email_2 !== null}>
                      <Link href={`mailto:${consultant.first_name} ${consultant.last_name}<${consultant.email_2}>`}>
                        {consultant.email_2}
                      </Link>
                    </ViewProperty>

                    <ViewProperty
                      label={
                        <>
                          Phone {consultant.phone_type !== "" && consultant.phone_type !== null && <small>({consultant.phone_type})</small>}
                          {consultant.preferred_com === "phone" && <StarIcon fontSize="small" />}
                        </>
                      }
                    >
                      <Link href={`tel:${getConsultantPhone(consultant)}`}>{getConsultantPhone(consultant)}</Link>
                    </ViewProperty>

                    <ViewProperty
                      label={
                        <>
                          Phone 2{" "}
                          {consultant.phone_2_type !== "" && consultant.phone_2_type !== null && <small>({consultant.phone_2_type})</small>}
                        </>
                      }
                      show={consultant.phone_2 !== "" && consultant.phone_2 !== null}
                    >
                      <Link href={`tel:${getConsultantPhone2(consultant)}`}>{getConsultantPhone2(consultant)}</Link>
                    </ViewProperty>

                    <Box sx={{ mt: 2 }}>
                      <ViewProperty label="Address" show={consultant.address !== "" && consultant.address !== null}>
                        <Grid container alignContent="start">
                          <Grid item xs={12}>
                            {consultant.address}
                          </Grid>
                          <Grid item xs={12}>
                            {consultant.address_2}
                          </Grid>
                          <Grid item xs={12}>
                            {consultant.city}, {consultant.state_region} {consultant.postal_code}
                            <Box component="span" sx={{ pl: 1 }}>
                              {consultant.country}
                            </Box>
                          </Grid>
                        </Grid>
                      </ViewProperty>

                      <ViewProperty label="Long, Lat" show={consultant.latitude !== 0}>
                        <GoogleMapsGeoLink latitude={consultant.latitude} longitude={consultant.longitude} />
                        {" | "}
                        {consultant.radius} miles
                      </ViewProperty>

                      <ViewProperty label="Address 2" show={consultant.address_alt !== "" && consultant.address_alt !== null}>
                        <Grid container alignContent="start">
                          <Grid item xs={12}>
                            {consultant.address_alt}
                          </Grid>
                          <Grid item xs={12}>
                            {consultant.address_2_alt}
                          </Grid>
                          <Grid item xs={12}>
                            {consultant.city_alt}, {consultant.state_region_alt} {consultant.postal_code_alt}
                            <Box component="span" sx={{ pl: 1 }}>
                              {consultant.country_alt}
                            </Box>
                          </Grid>
                        </Grid>
                      </ViewProperty>

                      <ViewProperty label="Long, Lat 2" show={consultant.latitude_alt !== 0}>
                        <GoogleMapsGeoLink latitude={consultant.latitude_alt} longitude={consultant.longitude_alt} />
                        {" | "}
                        {consultant.radius_alt} miles
                      </ViewProperty>

                      <ViewProperty label="Will Travel Countries">
                        {consultant.will_travel_regions?.length !== undefined && consultant.will_travel_regions.length > 0
                          ? consultant.will_travel_regions.join(", ")
                          : "None"}
                      </ViewProperty>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    {consultant.longitude !== 0 && consultant.latitude !== 0 && (
                      <ConsultantLocationMap primaryLocation={consultantLocation(consultant)} additionalLocations={additionalLocations} />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <ViewProperty label="Specialties" vertical>
                      <ConsultantSpecialties specialties={consultant.specialties} />
                    </ViewProperty>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <PrivateComponent
                      groupNames={[ACCESS_EXPENSE_REPORT_GROUP]}
                      component={<ViewProperty label="Expense Report Payment Type">{consultant.expense_report_payment_type}</ViewProperty>}
                    />
                    <ViewProperty label="Property Experience">
                      <CheckMark value={consultant.start_of_property_experience} />
                    </ViewProperty>

                    <ViewProperty label="HPR Experience">
                      <CheckMark value={consultant.start_of_hpr_experience} />
                    </ViewProperty>

                    <ViewProperty label="Casualty Experience">
                      <CheckMark value={consultant.start_of_casualty_experience} />
                    </ViewProperty>

                    <ViewProperty label="Other Experience">{consultant.other_experience}</ViewProperty>

                    <ViewProperty label="Contract Date">
                      <CheckMark
                        value={consultant.contract_date !== null && <Moment format={DATE_FORMAT}>{consultant.contract_date}</Moment>}
                      />
                    </ViewProperty>

                    <ViewProperty label="Sign Date">
                      <CheckMark
                        value={
                          consultant.sign_date !== "" &&
                          consultant.sign_date !== null && <Moment format={DATE_FORMAT}>{consultant.sign_date}</Moment>
                        }
                      />
                    </ViewProperty>

                    <ViewProperty label="Business Cards Received">
                      <CheckMark
                        value={
                          consultant.business_cards_received !== null && (
                            <Moment format={DATE_FORMAT}>{consultant.business_cards_received}</Moment>
                          )
                        }
                      />
                    </ViewProperty>
                    <ViewProperty label="Imported" show={consultant.imported}>
                      <CheckMark value={" "} />
                    </ViewProperty>

                    <ViewProperty label="Created">
                      <Moment format={DATE_TIME_FORMAT}>{consultant.created}</Moment>
                    </ViewProperty>

                    <ViewProperty label="Updated">
                      <Moment format={DATE_TIME_FORMAT}>{consultant.updated}</Moment>
                    </ViewProperty>
                  </Grid>
                  <Grid item xs={6}>
                    <ViewProperty label="Resume">
                      <ConsultantFileEditor
                        consultant={consultant}
                        action="delete_resume"
                        theFile={consultant.resume}
                        onChange={handleConsultantChange}
                      />
                    </ViewProperty>
                    <ViewProperty label="Profile Photo">
                      <ConsultantFileEditor
                        consultant={consultant}
                        action="delete_profile_photo"
                        theFile={consultant.profile_image}
                        onChange={handleConsultantChange}
                      />
                    </ViewProperty>
                    <ViewProperty label="Send Weekly Reminder Email">
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                          <CheckMark value={consultant.send_weekly_reminder_email} />
                        </Grid>
                        {consultant.send_weekly_reminder_email && (
                          <Grid item>
                            <LoadingButton loading={loading || saving} onClick={handleSendReminderEmail}>
                              Send Now
                            </LoadingButton>
                          </Grid>
                        )}
                      </Grid>
                    </ViewProperty>
                    <ViewProperty label="Languages" vertical show={consultant.languages?.length > 0}>
                      {consultant.languages?.map(language => (
                        <Chip sx={styles.languages.chip} key={language.id} avatar={<Avatar>{language.id}</Avatar>} label={language.name} />
                      ))}
                    </ViewProperty>
                    <ViewProperty label="Notes" vertical>
                      <Box dangerouslySetInnerHTML={{ __html: consultant.notes }}></Box>
                    </ViewProperty>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <ConsultantWorkAssignmentsPanel consultant={consultant} page={page} setPage={setPage} />
              </TabPanel>
              <TabPanel value={tab} index={3}>
                <WorkHistory consultant={consultant} page={page} setPage={setPage} />
              </TabPanel>
              <TabPanel value={tab} index={4}>
                <AcceptDeclineHistory consultant={consultant} page={page} setPage={setPage} />
              </TabPanel>
              <TabPanel value={tab} index={5}>
                <Box
                  sx={{
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <SpecialtiesEditor consultant={consultant} onChange={handleConsultantChange} />
                </Box>
              </TabPanel>

              <TabPanel value={tab} index={6}>
                <PrivateComponent groupNames={[ACCESS_EXPENSE_REPORT_GROUP]} component={<ExpenseReportsEditor consultant={consultant} />} />
              </TabPanel>
            </Grid>
          </Grid>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default ViewPage
