import * as React from "react"
import { useCallback, useState } from "react"
import { type IWorkAssignmentConsultant, WORK_ASSIGNMENT_CONSULTANT_ENDPOINT } from "../../../shared/models/IWorkAssignmentConsultant"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Rating,
  Box
} from "@mui/material"
import { styled } from "@mui/material/styles"
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LooksThreeIcon from '@mui/icons-material/Looks3';
import LooksFourIcon from '@mui/icons-material/Looks4';
import LooksFiveIcon from '@mui/icons-material/Looks5';
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { type IConsultant } from "../../../shared/models/main/IConsultant"
import { CONNECTION_ERROR, type IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import HelpDocsDrawer from "../../../shared/components/help/HelpDocsDrawer"
import DialogControls from "../../../shared/components/DialogControls"

interface IProps {
  wac?: IWorkAssignmentConsultant
  reviewType?: "Admin" | "Tech" | "Tech 2"
  buttonColor?: "success" | "warning"
  onChange: () => void
}

interface IPatch {
  scoring_formatting_quality: number | null // Do we need to have this be or null? Do we need to update the model and interface?
  scoring_technical_quality: number | null
  scoring_responsiveness: number | null
  scoring_admin_review_comment: string
  scoring_tech_review_comment: string
  scoring_tech_2_review_comment: string
}

const repository = new RestRepository<IPatch>(WORK_ASSIGNMENT_CONSULTANT_ENDPOINT)

// Rating labels for helper text
const labels: Record<number, string>  = {
  1: '1 - Very Poor',
  2: '2 - Poor',
  3: '3 - Fair',
  4: '4 - Good',
  5: '5 - Excellent',
};

// Styled Rating component from Material-UI
const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

// Custom icons for the Rating component from Material-UI
const customIcons: Record<number, {icon: React.ReactElement<unknown>; label: string;}> = {
  1: {
    icon: <LooksOneIcon color="error" />,
    label: 'One',
  },
  2: {
    icon: <LooksTwoIcon color="error" />,
    label: 'Two',
  },
  3: {
    icon: <LooksThreeIcon/>, // Default color is yellow
    label: 'Three',
  },
  4: {
    icon: <LooksFourIcon color="success" />,
    label: 'Four',
  },
  5: {
    icon: <LooksFiveIcon color="success" />,
    label: 'Five',
  },
};

/**
 * Custom IconContainer to render the icons based on the value from Material-UI Custom Rating component.
 * 
 * @param {object} props - The props for the component.
 * @param {number} props.value - The value used to determine which icon to render.
 * @returns {React.JSX.Element} The rendered icon wrapped in a <span> element.
 */
function IconContainer(props: { value: number}) : React.JSX.Element {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

/**
 * Edit the work assignment consultant report score.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const ConsultantScoring: React.FunctionComponent<IProps> = (props: IProps) => {
  const { wac, onChange, reviewType = "Review", buttonColor = "info" } = props

  const consultant = wac?.consultant as IConsultant
  const [scoringFormattingQuality, setScoringFormattingQuality] = useState<number | null>(null)
  const [scoringTechnicalQuality, setScoringTechnicalQuality] = useState<number | null>(null)
  const [scoringResponsiveness, setScoringResponsiveness] = useState<number | null>(null)
  const [adminReviewComment, setAdminReviewComment] = useState<string>("")
  const [techReviewComment, setTechReviewComment] = useState<string>("")
  const [tech2ReviewComment, setTech2ReviewComment] = useState<string>("")
  const [open, setOpen] = useState(false)

  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const handleOpen = useCallback(() => {
    if (wac !== undefined) {
      setScoringFormattingQuality(wac.scoring_formatting_quality)
      setScoringTechnicalQuality(wac.scoring_technical_quality)
      setScoringResponsiveness(wac.scoring_responsiveness)
      setAdminReviewComment(wac.scoring_admin_review_comment)
      setTechReviewComment(wac.scoring_tech_review_comment)
      setTech2ReviewComment(wac.scoring_tech_2_review_comment)
    }
    setOpen(true)
  }, [wac])

  const handleCancel = useCallback(() => {setOpen(false)}, [])

  const handleUpdate = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()
      setSavingError(null)

      try {
        if (wac?.id !== undefined) {
          setSaving(true)
          const patch: IPatch = {
            scoring_formatting_quality: scoringFormattingQuality,
            scoring_technical_quality: scoringTechnicalQuality,
            scoring_responsiveness: scoringResponsiveness,
            scoring_admin_review_comment: adminReviewComment,
            scoring_tech_review_comment: techReviewComment,
            scoring_tech_2_review_comment: tech2ReviewComment
          }
          await repository.patch(patch, wac.id)
          onChange()
          setSaving(false)
          setOpen(false)
        }
      } catch (reason: any) {
        setSaving(false)
        if (reason?.response !== undefined) {
          setSavingError(reason.response as IConnectionError)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
    },
    [wac, scoringFormattingQuality, scoringTechnicalQuality, scoringResponsiveness, adminReviewComment, techReviewComment, tech2ReviewComment]
  )

  const handleScoringFormattingQuality = useCallback((event: React.SyntheticEvent<Element, Event>, newValue: number | null) => {
    setScoringFormattingQuality(newValue)
  }, [])

  const handleScoringTechnicalQuality = useCallback((event: React.SyntheticEvent<Element, Event>, newValue: number | null) => {
    setScoringTechnicalQuality(newValue)
  }, [])

  const handleScoringResponsiveness = useCallback((event: React.SyntheticEvent<Element, Event>, newValue: number | null) => {
    setScoringResponsiveness(newValue)
  }, [])

  const handleAdminReviewComment = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminReviewComment(event.target.value)
  }, [])

    const handleTechReviewComment = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTechReviewComment(event.target.value)
    }, [])

    const handleTech2ReviewComment = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTech2ReviewComment(event.target.value)
    }, [])

  return (
    <>
    {/* Displays button */}
    <Button color={buttonColor} onClick={handleOpen}>{reviewType}</Button>
      {/* Displays dialog box */}
      <Dialog open={open} disableEscapeKeyDown={true} maxWidth="sm" fullWidth>
        <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
              <Grid item>
                  Score <em>{consultant?.name}</em>
              </Grid>
              <Grid item>
                <Typography component="span">
                  Scoring Criteria
                </Typography>
                <HelpDocsDrawer showButton showButtonLabel={false} pageId={559} />
              </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
          <form method="post" onSubmit={handleUpdate}>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems="top">
                {savingError !== null && <Grid item xs={12}>
                <ErrorMessage error={savingError} />
            </Grid>}
            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'flex-start', ml:0.25, }}>
              <Grid item xs>
                <Box
                  sx={{
                    border: '1px solid #ccc', // Border around the container
                    padding: '8px', // Padding inside the container
                    borderRadius: '8px', // Rounded corners
                    display: 'flex',
                    flexDirection: 'column', // Stack the label, rating, and helper text vertically
                    alignItems: 'flex-start', // Align everything to the left
                  }}
                >
                  {/* Label for the rating */}
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Responsiveness
                  </Typography>

                  {/* Rating component */}
                  <StyledRating
                    name="responsiveness-rating"
                    value={scoringResponsiveness}
                    precision={1}
                    IconContainerComponent={IconContainer}
                    getLabelText={(value: number) => customIcons[value].label}
                    onChange={handleScoringResponsiveness}
                    highlightSelectedOnly
                  />
                  
                  {/* Helper text (rating label) */}
                  <Box sx={{ mt: 1, fontSize: '0.75rem', color: '#555',fontStyle: scoringResponsiveness === null ? "italic" : "normal" }}>
                    {scoringResponsiveness === null ? "Not Scored" : labels[scoringResponsiveness]}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs>
                <Box
                  sx={{
                    border: '1px solid #ccc', // Border around the container
                    padding: '8px', // Padding inside the container
                    borderRadius: '8px', // Optional: rounded corners
                    display: 'flex',
                    flexDirection: 'column', // Stack the label, rating, and helper text vertically
                    alignItems: 'flex-start', // Align everything to the left
                  }}
                >
                  {/* Label for the rating */}
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Formatting Quality
                  </Typography>

                  {/* Rating component */}
                  <StyledRating
                    name="formatting-quality-rating"
                    value={scoringFormattingQuality}
                    precision={1}
                    IconContainerComponent={IconContainer}
                    getLabelText={(value: number) => customIcons[value].label}
                    onChange={handleScoringFormattingQuality}
                    highlightSelectedOnly
                  />
                  
                  {/* Helper text (rating label) */}
                  <Box sx={{ mt: 1, fontSize: '0.75rem', color:'#555',fontStyle: scoringFormattingQuality === null ? "italic" : "normal" }}>
                    {scoringFormattingQuality === null ? "Not Scored" : labels[scoringFormattingQuality]}
                  </Box>
                </Box>
              </Grid>
              {(reviewType === "Tech" || reviewType === "Tech 2") && (
              <Grid item xs>
                <Box
                  sx={{
                    border: '1px solid #ccc', // Border around the container
                    padding: '8px', // Padding inside the container
                    borderRadius: '8px', // Optional: rounded corners
                    display: 'flex',
                    flexDirection: 'column', // Stack the label, rating, and helper text vertically
                    alignItems: 'flex-start', // Align everything to the left
                  }}
                >
                  {/* Label for the rating */}
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Technical Quality
                  </Typography>

                  {/* Rating component */}
                  <StyledRating
                    name="technical-quality-rating"
                    value={scoringTechnicalQuality}
                    precision={1}
                    IconContainerComponent={IconContainer}
                    getLabelText={(value: number) => customIcons[value].label}
                    onChange={handleScoringTechnicalQuality}
                    highlightSelectedOnly
                  />
                  
                  {/* Helper text (rating label) */}
                  <Box sx={{ mt: 1, fontSize: '0.75rem', color: '#555',fontStyle: scoringTechnicalQuality === null ? "italic" : "normal" }}>
                    {scoringTechnicalQuality === null ? "Not Scored" : labels[scoringTechnicalQuality]}
                  </Box>
                </Box>
              </Grid>
              )}
            </Grid>
            {/* Reviewer Comment Boxes */}
              {reviewType === "Admin" && (
                  <Grid item xs={12}>
                      <TextField
                      fullWidth
                      multiline
                      label="Admin Review Comment"
                      value={adminReviewComment}
                      disabled={saving}
                      onChange={handleAdminReviewComment}
                      />
                  </Grid>
              )}
              {reviewType === "Tech" && (
                  <Grid item xs={12}>
                      <TextField
                          fullWidth
                          multiline
                          label="Tech Review Comment"
                          value={techReviewComment}
                          disabled={saving}
                          onChange={handleTechReviewComment}
                          // Not adding unless issues with people not entering comments
                          // error = {techReviewComment.length === 0}
                      />
                  </Grid>
              )}
              {reviewType === "Tech 2" && (
                  <Grid item xs={12}>
                      <TextField
                      fullWidth
                      multiline
                      label="Tech 2 Review Comment"
                      value={tech2ReviewComment}
                      disabled={saving}
                      onChange={handleTech2ReviewComment}
                      />
                  </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleUpdate} onCancel={handleCancel} loading={saving} buttonLabel="Save"/>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConsultantScoring